<template>
  <div>
      <div class="row">
        <div class="col-xxl-12 order-1 order-xxl-2">
        <ClientTable></ClientTable>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClientTable from "@/view/content/widgets/datatable/ClientTable.vue";
import {UPDATE_ACCOUNT_INFO} from "@/core/services/store/profile.module";

export default {
  name: "dashboard",
  components: {
    ClientTable
  },
  data() {
    return {
    }
  },
  mounted() {
    if(!this.currentUserAccountInfo.username){
      this.uploadAccount();
    }
  },
  methods: {
     uploadAccount(){
      this.$store
          .dispatch(UPDATE_ACCOUNT_INFO, )
          .then(() => {});
    },
  },
  computed: {
    ...mapGetters(["currentUserAccountInfo"]),
  },
};
</script>
