<template>
    <div v-if="params.data">
       <a
      :href="`/projects/detail/${params.data.id}`"
    >
      <h6 class="mb-0 font-weight-bold mt-4 text-center">{{ params.data.name}}</h6>
       </a>
    </div>
</template>

<script>

    export default {
        name: "NameRenderer",
        components: {
        },
        props: {
            params: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        computed:{

        },
        methods: {

        }
    }
</script>

